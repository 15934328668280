<template>
  <section class="section">
    <UserInfo v-if="user.data.superAdmin" />
    <AddRequest v-if="user.data.superAdmin" @add-request="createRequest" />

    <ListRequests
      :requests="requests"
      @reject-request="rejectRequest"
      @approve-request="approveRequest"
    />
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {
  db,
  serverTimestamp,
  functions,
  FunctionUsersAddMemberRole,
} from "@/firecontainer";
import UserInfo from "../core/UserInfo.vue";
import AddRequest from "./AddRequest.vue";
import ListRequests from "./ListRequests.vue";

export default {
  name: "RequestsPage",
  components: {UserInfo, AddRequest, ListRequests},
  data() {
    return {
      isLoading: false,
      requests: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler: function () {
        this.fetchRequests();
      },
    },
  },
  methods: {
    async fetchRequests() {
      try {
        if (this.user.data.superAdmin) {
          const querySnapshot = await db
            .collection("requests")
            .orderBy("createdAt", "asc")
            .get();
          console.log(querySnapshot.id);
          this.requests = await this.requestDetails(querySnapshot);
        } else if (
          (this.user.data.admin ?? []).includes(this.$route.params.id)
        ) {
          const communityId = this.$route.params.id;
          const querySnapshot = await db
            .collection("requests")
            .where("communityId", "==", communityId)
            .orderBy("createdAt", "asc")
            .get();
          this.requests = await this.requestDetails(querySnapshot);
          console.log(this.requests);
        } else {
          throw "Not valid user";
        }
      } catch (e) {
        console.error("Erro ao buscar os pedidos:", e);
      }
    },

    async requestDetails(request) {
      try {
        return await Promise.all(
          request.docs.map(async (doc) => {
            const requestData = doc.data();
            const [userProfile, communityData] = await Promise.all([
              db.collection("profiles").doc(requestData.userId).get(),
              db.collection("communities").doc(requestData.communityId).get(),
            ]);
            const data = {
              ...requestData,
              id: doc.id,
              userProfile: userProfile.exists ? userProfile.data() : null,
              communityData: communityData.exists ? communityData.data() : null,
            };
            console.log(data);
            return data;
          })
        );
      } catch (error) {
        console.error(error);
      }
    },

    async createRequest(request) {
      console.log("creating request");
      this.isLoading = true;

      const res = await db.collection("requests").add({
        userId: request.userId,
        communityId: request.communityId,
        createdAt: serverTimestamp(),
      });

      console.log(`created: ${res}`);
      await this.fetchRequests();
      this.isLoading = false;
    },

    async clearRequest(request) {
      try {
        console.log(`deleting: ${request.id} from database`);
        const res = await db.collection("requests").doc(request.id).delete();
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    },

    async rejectRequest(request) {
      this.isLoading = true;
      await this.clearRequest(request);
      await this.fetchRequests();
      this.isLoading = false;
    },

    async approveRequest(request) {
      this.$confirm({
        message: "Tem certeza que deseja aprovar?",
        button: {
          no: "NÃO",
          yes: "SIM",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.isLoading = true;
            console.log(
              `adding ${request.userId} as member of ${request.communityId}`
            );

            await this.addMemberToCommunity(
              request.userId,
              request.communityId
            );

            const addMemberRoleFunction = functions.httpsCallable(
              FunctionUsersAddMemberRole
            );
            const result = await addMemberRoleFunction({
              userId: request.userId,
              communityId: request.communityId,
            });

            console.log(result.data.message);
            // TODO: check if success
            await this.clearRequest(request);
            await this.fetchRequests();
            this.isLoading = false;
          }
        },
      });
    },

    async addMemberToCommunity(memberId, communityId) {
      const communityRef = db.collection("communities").doc(communityId);
      const profileIdToAdd = memberId;

      const communityDoc = await communityRef.get();
      const membersArray = communityDoc.data().members || [];

      if (!membersArray.includes(profileIdToAdd)) {
        membersArray.push(profileIdToAdd);

        await communityRef.update({members: membersArray});

        console.log(
          `Adicionado ${profileIdToAdd} como membro de ${communityId}`
        );
      } else {
        console.log(`${profileIdToAdd} já é membro de ${communityId}`);
      }
    },
  },
};
</script>
