<template>
  <div>
    <div class="tile is-ancestor is-vertical">
      <div v-for="ad in ads" :key="ad.id" class="tile is-parent">
        <article class="tile is-child box">
          <div class="buttons is-pulled-right">
            <button @click="$emit('delete-ad', ad.id)" class="button is-danger is-outlined">
              <span class="material-icons">delete</span>
            </button>
          </div>

          <p class="title">{{ ad.title }}</p>
          <p class="subtitle">Usuário: {{ ad.owner }}</p>
          <div class="content">
            <p>
              {{ ad.description }}
            </p>
            <span>{{ formatPrice(ad.price) }}</span>

            <div v-for="(image, index) in ad.images" :key="`image-${index}`">
              <img :src="image" width="200" />
            </div>

            <span class="tags">
              <span v-if="ad.createdAt" class="tag">
                <time>{{ ad.createdAt.toDate() | formatDate }}</time>
              </span>
              <span v-if="ad.expiresAt" class="tag is-danger">
                <time>{{ ad.expiresAt.toDate() | formatDate }}</time>
              </span>
            </span>

            <!-- <button @click="test(ad.id)" class="button">RUN</button> -->
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from '@/firecontainer'

export default {
  props: ['communityId', 'ads'],
  methods: {
    formatPrice(number) {
      const price = number / 100
      const text = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price)
      return text
    },
    test(a) {
      console.log(this.communityId)
      console.log(a)

      const sRef = storage.ref().child(this.communityId).child(a).child('1db07cf5-014a-43b0-b7c0-cdb0376b68cf.jpg')
      console.log(sRef.getDownloadURL())
    },
  },
}
</script>
