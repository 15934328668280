<template>
  <div class="tile is-ancestor is-vertical">
    <div v-if="!profiles.length" class="card">
      <div class="card-content">
        <div class="content">Nenhum usuário vínculado.</div>
      </div>
    </div>

    <div
      v-for="profile in profiles"
      :key="profile.email"
      class="tile is-parent"
    >
      <article class="tile is-child box">
        <div class="columns">
          <div v-if="profile.photoURL" class="column is-one-fifth">
            <figure class="image is-16by9">
              <img :src="profile.photoURL" alt="" />
            </figure>
          </div>

          <div class="column">
            <p class="subtitle" v-if="profile.displayName">
              Usuário: {{ profile.displayName }}
            </p>

            <p class="subtitle" v-if="profile.email">
              Email: {{ profile.email }}
            </p>

            <p class="subtitle" v-if="profile.contactInfo">
              Telefone: {{ profile.contactInfo }}
            </p>

            <p class="subtitle" v-if="profile.id">id: {{ profile.id }}</p>
          </div>
        </div>

        <div class="content">
          <div class="buttons is-right mt-4">
            <button
              @click="$emit('remove-user', profile)"
              class="button is-danger is-outlined"
            >
              REMOVER
            </button>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  props: ["profiles"],
};
</script>
