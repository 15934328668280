<template>
  <section class="section">
    <h2>
      <router-link to="/">{{ this.$route.params.name }}</router-link> &gt;
      Requisições Pendentes
    </h2>

    <AddRequest v-if="user.data.superAdmin" @add-request="createRequest" />

    <ListRequests
      :communityId="this.$route.params.id"
      :requests="requests"
      @reject-request="rejectRequest"
      @approve-request="approveRequest"
    />
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {
  db,
  serverTimestamp,
  functions,
  FunctionUsersAddMemberRole,
} from "@/firecontainer";
import AddRequest from "./AddRequest";
import ListRequests from "./ListRequests";

export default {
  name: "CommunityRequestsPage",
  components: {AddRequest, ListRequests},
  data() {
    return {
      requests: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler: function () {
        this.fetchRequests();
      },
    },
  },
  methods: {
    async fetchRequests() {
      try {
        const communityId = this.$route.params.id;

        // Check if the user is a superAdmin or an admin for the specific community
        if (
          this.user.data.superAdmin ||
          (this.user.data.admin ?? []).includes(communityId)
        ) {
          // Fetch requests for the specific community if a communityId is provided
          if (communityId) {
            const querySnapshot = await db
              .collection("requests")
              .where("communityId", "==", communityId)
              .orderBy("createdAt", "asc")
              .get();
            this.requests = await this.requestDetails(querySnapshot);
          } else if (this.user.data.superAdmin) {
            // If no specific communityId is provided and user is a superAdmin, fetch all requests
            const querySnapshot = await db
              .collection("requests")
              .orderBy("createdAt", "asc")
              .get();
            this.requests = await this.requestDetails(querySnapshot);
          } else {
            // If no communityId and not a superAdmin, reset requests (no access)
            this.requests = [];
          }
        } else {
          throw "Not a valid user";
        }
      } catch (e) {
        console.log(e);
        console.error("No results or query error");
        this.requests = [];
      }
    },

    async requestDetails(request) {
      try {
        return await Promise.all(
          request.docs.map(async (doc) => {
            const requestData = doc.data();
            console.log("userID: " + requestData.userId);
            console.log("CommunityID: " + requestData.communityId);
            const [userProfile, communityData] = await Promise.all([
              db.collection("profiles").doc(requestData.userId).get(),
              db.collection("communities").doc(requestData.communityId).get(),
            ]);
            const data = {
              ...requestData,
              id: doc.id,
              userProfile: userProfile.exists ? userProfile.data() : null,
              communityData: communityData.exists ? communityData.data() : null,
            };
            console.log(data);
            return data;
          })
        );
      } catch (error) {
        throw "Fetch error details";
      }
    },

    async createRequest(request) {
      console.log("creating request");
      this.isLoading = true;

      const res = await db.collection("requests").add({
        userId: request.userId,
        communityId: request.communityId,
        createdAt: serverTimestamp(),
      });

      console.log(`created: ${res}`);
      await this.fetchRequests();
      this.isLoading = false;
    },

    async clearRequest(request) {
      try {
        console.log(`deleting: ${request.id} from database`);
        const res = await db.collection("requests").doc(request.id).delete();
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    },

    async addUser(request) {
      try {
        await db.collection("users").add({
          userId: request.userId,
          userName: request.userName,
          userEmail: request.userEmail,
          userPhone: request.userPhone,
          communityId: request.communityId,
          communityName: request.communityName,
          communityDescription: request.communityDescription,
          communityAddress: request.communityAddress,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      } catch (error) {
        console.error(error);
      }
    },

    async rejectRequest(request) {
      this.$confirm({
        message: "Tem certeza que deseja rejeitar?",
        button: {
          no: "NÃO",
          yes: "SIM",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.isLoading = true;
            await this.clearRequest(request);
            await this.fetchRequests();
            this.isLoading = false;
          }
        },
      });
    },

    async approveRequest(request) {
      this.$confirm({
        message: "Tem certeza que deseja aprovar?",
        button: {
          no: "NÃO",
          yes: "SIM",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.isLoading = true;
            console.log(
              `adding ${request.userId} as member of ${request.communityId}`
            );

            await this.addMemberToCommunity(
              request.userId,
              request.communityId
            );

            const addMemberRoleFunction = functions.httpsCallable(
              FunctionUsersAddMemberRole
            );
            const result = await addMemberRoleFunction({
              userId: request.userId,
              communityId: request.communityId,
            });

            console.log(result.data.message);
            // TODO: check if success
            await this.clearRequest(request);
            await this.fetchRequests();
            this.isLoading = false;
          }
        },
      });
    },

    async addMemberToCommunity(memberId, communityId) {
      const communityRef = db.collection("communities").doc(communityId);
      const profileIdToAdd = memberId;

      const communityDoc = await communityRef.get();
      const membersArray = communityDoc.data().members || [];

      if (!membersArray.includes(profileIdToAdd)) {
        membersArray.push(profileIdToAdd);

        await communityRef.update({members: membersArray});

        console.log(
          `Adicionado ${profileIdToAdd} como membro de ${communityId}`
        );
      } else {
        console.log(`${profileIdToAdd} já é membro de ${communityId}`);
      }
    },
  },
};
</script>
