<template>
  <section class="section">
    <AddCommunity
      v-if="user.data.superAdmin"
      @add-community="createCommunity"
    />

    <!-- <button @click="createCommunity()" class="button">Create New Community</button> -->
    <ListCommunities
      :superAdmin="user.data.superAdmin"
      @community-users="routeToCommunityUsers"
      @community-ads="routeToCommunityAds"
      @community-requests="routeToCommunityRequests"
      @delete-community="deleteCommunity"
      :communities="communities"
    />
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {db, serverTimestamp, GeoPoint, FieldPath} from "@/firecontainer";
import AddCommunity from "./AddCommunity";
import ListCommunities from "./ListCommunities";

export default {
  name: "CommunityPage",
  data() {
    return {
      communities: [],
    };
  },
  components: {
    AddCommunity,
    ListCommunities,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler(u) {
        if (u.data.superAdmin) {
          this.$bind(
            "communities",
            db.collection("communities").orderBy("createdAt", "desc")
          );
        } else {
          if (Array.isArray(u.data.admin) && u.data.admin.length > 0) {
            this.$bind(
              "communities",
              db
                .collection("communities")
                .where(FieldPath.documentId(), "in", u.data.admin)
            );
          } else {
            console.warn("User is not admin or admin data is empty");
          }
        }
      },
    },
  },
  methods: {
    async createCommunity(community) {
      await db.collection("communities").add({
        name: community.name,
        description: community.description,
        address: community.address,
        geolocation: new GeoPoint(
          community.location.latitude,
          community.location.longitude
        ),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    },

    async deleteCommunity(communityId) {
      this.$confirm({
        message: "Tem certeza que deseja excluir esta comunidade?",
        button: {
          no: "NÃO",
          yes: "SIM",
        },
        callback: async (confirm) => {
          if (confirm) {
            await db.collection("communities").doc(communityId).delete();
          }
        },
      });
    },

    async routeToCommunityUsers(communityId, communityName) {
      this.$router.push({
        name: "CommunityUsers",
        params: {id: communityId, name: communityName},
      });
    },

    async routeToCommunityAds(communityId, communityName) {
      this.$router.push({
        name: "CommunityAds",
        params: {id: communityId, name: communityName},
      });
    },

    async routeToCommunityRequests(communityId, communityName) {
      this.$router.push({
        name: "CommunityRequests",
        params: {id: communityId, name: communityName},
      });
    },
  },
};
</script>
