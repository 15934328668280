<template>
  <section class="section">
    <h2>Requisições Pendentes</h2>

    <div class="tile is-ancestor is-vertical">
      <div v-for="request in requests" :key="request.id" class="tile is-parent">
        <article class="tile is-child box">
          <p>
            <span
              class="title"
              v-if="request.communityData && request.communityData.name"
              >Comunidade:{{ request.communityData.name }}</span
            >
            <br />
            <span class="subtitle">ID:{{ request.communityId }}</span>
          </p>
          <div class="tags">
            <time v-if="request.createdAt" class="tag">{{
              request.createdAt.toDate() | formatDate
            }}</time>
          </div>
          <hr />
          <div class="columns">
            <div
              v-if="request.userProfile && request.userProfile.photoURL"
              class="column is-one-fifth"
            >
              <figure class="image is-16by9">
                <img :src="request.userProfile.photoURL" alt="" />
              </figure>
            </div>
            <div class="column">
              <p class="subtitle">ID do Usuário: {{ request.userId }}</p>
              <p
                class="subtitle"
                v-if="request.userProfile && request.userProfile.displayName"
              >
                Usuário: {{ request.userProfile.displayName }}
              </p>
              <p
                class="subtitle"
                v-if="request.userProfile && request.userProfile.email"
              >
                Email: {{ request.userProfile.email }}
              </p>
              <p
                class="subtitle"
                v-if="request.userProfile && request.userProfile.contactInfo"
              >
                Telefone: {{ request.userProfile.contactInfo }}
              </p>
            </div>
          </div>
          <div class="content">
            <div class="buttons is-right mt-4">
              <button
                @click="$emit('reject-request', request)"
                class="button is-danger is-outlined"
              >
                RECUSAR
              </button>
              <button
                @click="$emit('approve-request', request)"
                class="button is-primary is-outlined"
              >
                ACEITAR
              </button>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["requests"],
};
</script>
