<template>
  <div>
    <div class="tile is-ancestor is-vertical">
      <div v-for="community in communities" :key="community.id" class="tile is-parent">
        <article class="tile is-child box">
          <p class="title">{{ community.name }}</p>
          <p class="subtitle">{{ community.address }}</p>
          <div class="content">
            <div class="buttons">
              <button
                @click="$emit('community-users', community.id, community.name)"
                class="button is-primary is-outlined"
              >
                USUÁRIOS
              </button>
              <button
                @click="$emit('community-requests', community.id, community.name)"
                class="button is-primary is-outlined"
              >
                SOLICITAÇÕES
              </button>
              <button
                @click="$emit('community-ads', community.id, community.name)"
                class="button is-primary is-outlined"
              >
                ANÚNCIOS
              </button>
              <button
                v-if="superAdmin"
                @click="$emit('delete-community', community.id, community.name)"
                class="button is-danger is-outlined"
              >
                DELETAR
              </button>
            </div>

            <qrcode-vue :value="community.joinLink" :size="size" level="H"></qrcode-vue>

            <div class="tags has-addons">
              <span class="tag is-dark">Código de acesso</span>
              <span class="tag is-info">{{ community.joinCode }}</span>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  props: ['superAdmin', 'communities'],
  components: {
    QrcodeVue,
  },
  data() {
    return {
      value: 'https://example.com',
      size: 300,
    }
  },
}
</script>
