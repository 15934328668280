<template>
  <nav
    class="navbar is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img src="@/assets/logo_large.png" alt="Logo" />
      </router-link>

      <a
        role="button"
        v-show="isLoggedIn"
        @click="showNav = !showNav"
        class="navbar-burger"
        :class="{'is-active': showNav}"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasic"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <!-- added a 4th span for the hamburger to display 3 sticks, based on documentation-->
      </a>
    </div>

    <div
      v-if="isLoggedIn"
      id="navbarBasic"
      class="navbar-menu"
      :class="{'is-active': showNav}"
    >
      <div class="navbar-start">
        <router-link v-if="user.data.superAdmin" class="navbar-item" to="/users"
          >Usuários</router-link
        >
        <router-link
          v-if="user.data.superAdmin"
          class="navbar-item"
          to="/communities"
          >Comunidades</router-link
        >
        <router-link
          v-if="user.data.superAdmin"
          class="navbar-item"
          to="/requests"
          >Requisições</router-link
        >

        <div
          v-if="user.data.superAdmin"
          class="navbar-item has-dropdown is-hoverable"
        >
          <a class="navbar-link">Mais</a>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" to="/profiles"
              >Profiles</router-link
            >
            <a class="navbar-item">Reportar um problema</a>
            <hr class="navbar-divider" />
            <div class="navbar-item">v1.0.0</div>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a @click="signOut()" class="button is-primary"
              ><strong>SAIR</strong></a
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";
import {auth} from "@/firecontainer";

export default {
  name: "NavBar",
  props: ["isLoggedIn"],
  methods: {
    signOut() {
      auth.signOut();
    },
  },
  data() {
    return {
      showNav: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
